// extracted by mini-css-extract-plugin
export var bxTextTruncateEnd = "Footer-module--bx--text-truncate--end--3JmCW";
export var bxTextTruncateFront = "Footer-module--bx--text-truncate--front--31Ykv";
export var footer = "Footer-module--footer--kT1gM";
export var bxGrid = "Footer-module--bx--grid--3CJhe";
export var FooterModuleGrid1FR_d = "Footer-module--Footer-module--grid--1FR_d--3SbWC";
export var bxRow = "Footer-module--bx--row--NjRE9";
export var grid = "Footer-module--grid--acYDy";
export var nav = "Footer-module--nav--3ZNeE";
export var listItem = "Footer-module--listItem--gIrnP";
export var content = "Footer-module--content--3yuFy";
export var logo = "Footer-module--logo--3uPP9";
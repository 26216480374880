import React from "react";
import { rotate, rotateInterior } from "./Stars.module.scss";

const Stars = ({ isHome }) => (
  <>
    <div>
      <div className={isHome === true ? rotate : rotateInterior}>
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          viewBox="0 0 599.456 593.71"
          enableBackground="new 0 0 599.456 593.71"
          id="stars"
        >
          <circle fill="#FFFFFF" cx="287.793" cy="0.398" r="0.398" />
          <circle fill="#FFFFFF" cx="300.965" cy="15.945" r="0.398" />
          <circle fill="#FFFFFF" cx="306.237" cy="22.961" r="0.398" />
          <circle fill="#FFFFFF" cx="282.496" cy="16.214" r="0.398" />
          <circle fill="#FFFFFF" cx="257.775" cy="32.352" r="0.398" />
          <circle fill="#FFFFFF" cx="287.624" cy="62.283" r="0.398" />
          <circle fill="#FFFFFF" cx="240.963" cy="68.985" r="0.398" />
          <circle fill="#FFFFFF" cx="183.157" cy="79.314" r="0.398" />
          <circle fill="#FFFFFF" cx="146.147" cy="75.118" r="0.398" />
          <circle fill="#FFFFFF" cx="104.511" cy="75.817" r="0.398" />
          <circle fill="#FFFFFF" cx="256.101" cy="75.548" r="0.398" />
          <circle fill="#FFFFFF" cx="238.941" cy="85.554" r="0.398" />
          <circle fill="#FFFFFF" cx="199.887" cy="82.994" r="0.398" />
          <circle fill="#FFFFFF" cx="176.917" cy="65.08" r="0.878" />
          <circle fill="#FFFFFF" cx="198.757" cy="44.455" r="0.398" />
          <circle fill="#FFFFFF" cx="133.344" cy="62.638" r="0.398" />
          <circle fill="#FFFFFF" cx="161.909" cy="57.85" r="0.398" />
          <circle fill="#FFFFFF" cx="157.283" cy="29.393" r="0.398" />
          <circle fill="#FFFFFF" cx="173.604" cy="58.872" r="0.398" />
          <circle fill="#FFFFFF" cx="251.83" cy="18.15" r="0.398" />
          <circle fill="#FFFFFF" cx="233.537" cy="26.273" r="0.398" />
          <circle fill="#FFFFFF" cx="264.063" cy="103.144" r="0.398" />
          <circle fill="#FFFFFF" cx="265.408" cy="108.308" r="0.398" />
          <circle fill="#FFFFFF" cx="269.012" cy="124.984" r="0.398" />
          <circle fill="#FFFFFF" cx="290.991" cy="154.549" r="0.398" />
          <circle fill="#FFFFFF" cx="255.079" cy="145.534" r="0.398" />
          <circle fill="#FFFFFF" cx="245.988" cy="116.754" r="0.398" />
          <circle fill="#FFFFFF" cx="231.55" cy="113.419" r="0.398" />
          <circle fill="#FFFFFF" cx="224.202" cy="123.209" r="0.398" />
          <circle fill="#FFFFFF" cx="229.581" cy="131.709" r="0.398" />
          <circle fill="#FFFFFF" cx="238.134" cy="141.607" r="0.398" />
          <circle fill="#FFFFFF" cx="230.872" cy="153.925" r="0.398" />
          <circle fill="#FFFFFF" cx="180.414" cy="170.709" r="0.398" />
          <circle fill="#FFFFFF" cx="256.317" cy="191.334" r="0.398" />
          <circle fill="#FFFFFF" cx="235.714" cy="193.948" r="0.398" />
          <circle fill="#FFFFFF" cx="224.094" cy="193.948" r="0.398" />
          <circle fill="#FFFFFF" cx="244.321" cy="208.795" r="0.398" />
          <circle fill="#FFFFFF" cx="266.936" cy="221.759" r="0.398" />
          <circle fill="#FFFFFF" cx="291.444" cy="227.999" r="0.398" />
          <circle fill="#FFFFFF" cx="259.652" cy="242.362" r="0.398" />
          <circle fill="#FFFFFF" cx="247.979" cy="240.103" r="0.398" />
          <circle fill="#FFFFFF" cx="239.802" cy="230.958" r="0.398" />
          <circle fill="#FFFFFF" cx="245.127" cy="225.009" r="0.398" />
          <circle fill="#FFFFFF" cx="233.669" cy="241.071" r="0.398" />
          <circle fill="#FFFFFF" cx="221.566" cy="236.983" r="0.398" />
          <circle fill="#FFFFFF" cx="189.881" cy="228.613" r="0.398" />
          <circle fill="#FFFFFF" cx="171.452" cy="204.922" r="0.398" />
          <circle fill="#FFFFFF" cx="199.403" cy="193.41" r="0.398" />
          <circle fill="#FFFFFF" cx="130.515" cy="219.123" r="0.398" />
          <circle fill="#FFFFFF" cx="112.849" cy="204.523" r="0.878" />
          <circle fill="#FFFFFF" cx="101.176" cy="199.865" r="0.398" />
          <circle fill="#FFFFFF" cx="105.372" cy="210.678" r="0.398" />
          <circle fill="#FFFFFF" cx="104.318" cy="220.522" r="0.398" />
          <circle fill="#FFFFFF" cx="74.656" cy="232.572" r="0.398" />
          <circle fill="#FFFFFF" cx="107.846" cy="235.638" r="0.398" />
          <circle fill="#FFFFFF" cx="84.338" cy="218.294" r="0.398" />
          <circle fill="#FFFFFF" cx="66.264" cy="214.551" r="0.398" />
          <circle fill="#FFFFFF" cx="10.964" cy="203.577" r="0.398" />
          <circle fill="#FFFFFF" cx="46.005" cy="194.593" r="0.398" />
          <circle fill="#FFFFFF" cx="40.389" cy="177.702" r="0.878" />
          <circle fill="#FFFFFF" cx="74.225" cy="144.296" r="0.398" />
          <circle fill="#FFFFFF" cx="125.19" cy="146.018" r="0.398" />
          <circle fill="#FFFFFF" cx="145.556" cy="159.896" r="0.398" />
          <circle fill="#FFFFFF" cx="183.857" cy="160.295" r="0.398" />
          <circle fill="#FFFFFF" cx="185.255" cy="181.844" r="0.398" />
          <circle fill="#FFFFFF" cx="117.475" cy="137.734" r="0.398" />
          <circle fill="#FFFFFF" cx="163.523" cy="116.862" r="0.398" />
          <circle fill="#FFFFFF" cx="164.814" cy="129.449" r="0.398" />
          <circle fill="#FFFFFF" cx="186.062" cy="113.871" r="0.398" />
          <circle fill="#FFFFFF" cx="202.093" cy="94.914" r="0.398" />
          <circle fill="#FFFFFF" cx="89.395" cy="106.587" r="0.398" />
          <circle fill="#FFFFFF" cx="89.686" cy="109.815" r="0.398" />
          <circle fill="#FFFFFF" cx="79.013" cy="106.587" r="0.398" />
          <circle fill="#FFFFFF" cx="56.742" cy="196.853" r="0.398" />
          <circle fill="#FFFFFF" cx="45.715" cy="147.201" r="0.398" />
          <circle fill="#FFFFFF" cx="75.022" cy="110.675" r="0.398" />
          <circle fill="#FFFFFF" cx="80.067" cy="116.862" r="0.398" />
          <circle fill="#FFFFFF" cx="112.203" cy="112.472" r="0.398" />
          <circle fill="#FFFFFF" cx="115.7" cy="254.197" r="0.398" />
          <circle fill="#FFFFFF" cx="96.227" cy="283.568" r="0.398" />
          <circle fill="#FFFFFF" cx="72.611" cy="282.492" r="0.398" />
          <circle fill="#FFFFFF" cx="50.986" cy="284.267" r="0.398" />
          <circle fill="#FFFFFF" cx="46.253" cy="288.947" r="0.398" />
          <circle fill="#FFFFFF" cx="21.723" cy="303.418" r="0.878" />
          <circle fill="#FFFFFF" cx="18.71" cy="333.488" r="0.398" />
          <circle fill="#FFFFFF" cx="54.913" cy="351.993" r="0.398" />
          <circle fill="#FFFFFF" cx="6.876" cy="342.902" r="0.398" />
          <circle fill="#FFFFFF" cx="12.632" cy="356.781" r="0.398" />
          <circle fill="#FFFFFF" cx="10.318" cy="339.513" r="0.398" />
          <circle fill="#FFFFFF" cx="0.398" cy="348.26" r="0.398" />
          <circle fill="#FFFFFF" cx="11.771" cy="323.989" r="0.398" />
          <circle fill="#FFFFFF" cx="31.965" cy="326.549" r="0.398" />
          <circle fill="#FFFFFF" cx="64.65" cy="303.095" r="0.398" />
          <circle fill="#FFFFFF" cx="64.22" cy="293.358" r="0.398" />
          <circle fill="#FFFFFF" cx="74.709" cy="293.358" r="0.398" />
          <circle fill="#FFFFFF" cx="84.199" cy="299.276" r="0.398" />
          <circle fill="#FFFFFF" cx="89.556" cy="303.418" r="0.398" />
          <circle fill="#FFFFFF" cx="106.286" cy="320.47" r="0.398" />
          <circle fill="#FFFFFF" cx="127.987" cy="303.019" r="0.398" />
          <circle fill="#FFFFFF" cx="128.611" cy="305.753" r="0.398" />
          <circle fill="#FFFFFF" cx="151.311" cy="297.285" r="0.398" />
          <circle fill="#FFFFFF" cx="179.445" cy="297.684" r="0.398" />
          <circle fill="#FFFFFF" cx="184.233" cy="299.814" r="0.398" />
          <circle fill="#FFFFFF" cx="203.384" cy="321.708" r="0.398" />
          <circle fill="#FFFFFF" cx="296.393" cy="309.335" r="0.398" />
          <circle fill="#FFFFFF" cx="260.997" cy="332.574" r="0.398" />
          <circle fill="#FFFFFF" cx="246.849" cy="331.014" r="0.398" />
          <circle fill="#FFFFFF" cx="269.604" cy="348.228" r="0.398" />
          <circle fill="#FFFFFF" cx="267.344" cy="352.8" r="0.398" />
          <circle fill="#FFFFFF" cx="253.842" cy="358.018" r="0.398" />
          <circle fill="#FFFFFF" cx="237.166" cy="350.057" r="0.398" />
          <circle fill="#FFFFFF" cx="230.496" cy="341.773" r="0.398" />
          <circle fill="#FFFFFF" cx="221.942" cy="343.655" r="0.398" />
          <circle fill="#FFFFFF" cx="219.898" cy="359.471" r="0.398" />
          <circle fill="#FFFFFF" cx="216.724" cy="361.246" r="0.398" />
          <circle fill="#FFFFFF" cx="221.996" cy="382.01" r="0.398" />
          <circle fill="#FFFFFF" cx="179.069" cy="354.791" r="0.398" />
          <circle fill="#FFFFFF" cx="182.189" cy="361.246" r="0.398" />
          <circle fill="#FFFFFF" cx="174.227" cy="359.525" r="0.398" />
          <circle fill="#FFFFFF" cx="166.589" cy="358.449" r="0.398" />
          <circle fill="#FFFFFF" cx="173.829" cy="364.043" r="0.398" />
          <circle fill="#FFFFFF" cx="146.309" cy="355.759" r="0.398" />
          <circle fill="#FFFFFF" cx="150.236" cy="371.305" r="0.398" />
          <circle fill="#FFFFFF" cx="141.951" cy="389.649" r="0.398" />
          <circle fill="#FFFFFF" cx="138.347" cy="406.11" r="0.398" />
          <circle fill="#FFFFFF" cx="156.669" cy="419.182" r="0.398" />
          <circle fill="#FFFFFF" cx="173.851" cy="411.543" r="0.398" />
          <circle fill="#FFFFFF" cx="175.25" cy="416.653" r="0.398" />
          <circle fill="#FFFFFF" cx="180.629" cy="417.675" r="0.398" />
          <circle fill="#FFFFFF" cx="166.589" cy="386.045" r="0.398" />
          <circle fill="#FFFFFF" cx="185.847" cy="379.751" r="0.398" />
          <circle fill="#FFFFFF" cx="187.299" cy="383.754" r="0.398" />
          <circle fill="#FFFFFF" cx="192.141" cy="380.719" r="0.398" />
          <circle fill="#FFFFFF" cx="190.85" cy="382.732" r="0.398" />
          <circle fill="#FFFFFF" cx="199.887" cy="390.047" r="0.398" />
          <circle fill="#FFFFFF" cx="213.152" cy="388.466" r="0.398" />
          <circle fill="#FFFFFF" cx="209.624" cy="400.515" r="0.398" />
          <circle fill="#FFFFFF" cx="220.544" cy="404.904" r="0.398" />
          <circle fill="#FFFFFF" cx="242.223" cy="416.761" r="0.398" />
          <circle fill="#FFFFFF" cx="242.223" cy="424.292" r="0.398" />
          <circle fill="#FFFFFF" cx="257.123" cy="435.051" r="0.398" />
          <circle fill="#FFFFFF" cx="272.939" cy="435.449" r="0.398" />
          <circle fill="#FFFFFF" cx="264.493" cy="458.612" r="0.398" />
          <circle fill="#FFFFFF" cx="257.554" cy="465.821" r="0.398" />
          <circle fill="#FFFFFF" cx="287.355" cy="470.07" r="0.398" />
          <circle fill="#FFFFFF" cx="269.711" cy="484.81" r="0.398" />
          <circle fill="#FFFFFF" cx="265.708" cy="499.334" r="0.398" />
          <circle fill="#FFFFFF" cx="254.757" cy="514.303" r="0.398" />
          <circle fill="#FFFFFF" cx="285.257" cy="532.762" r="0.398" />
          <circle fill="#FFFFFF" cx="301.525" cy="577.313" r="0.398" />
          <circle fill="#FFFFFF" cx="241.577" cy="569.104" r="0.398" />
          <circle fill="#FFFFFF" cx="225.116" cy="563.886" r="0.398" />
          <circle fill="#FFFFFF" cx="302.31" cy="515.956" r="0.398" />
          <circle fill="#FFFFFF" cx="244.643" cy="515.149" r="0.398" />
          <circle fill="#FFFFFF" cx="230.388" cy="506.758" r="0.398" />
          <circle fill="#FFFFFF" cx="228.236" cy="496.483" r="0.398" />
          <circle fill="#FFFFFF" cx="237.973" cy="489.813" r="0.398" />
          <circle fill="#FFFFFF" cx="228.774" cy="519.015" r="0.398" />
          <circle fill="#FFFFFF" cx="266.107" cy="493.955" r="0.398" />
          <circle fill="#FFFFFF" cx="285.957" cy="476.31" r="0.398" />
          <circle fill="#FFFFFF" cx="275.79" cy="428.671" r="0.398" />
          <circle fill="#FFFFFF" cx="280.233" cy="417.277" r="0.398" />
          <circle fill="#FFFFFF" cx="283.644" cy="415.846" r="0.398" />
          <circle fill="#FFFFFF" cx="302.74" cy="406.917" r="0.398" />
          <circle fill="#FFFFFF" cx="300.589" cy="412.834" r="0.398" />
          <circle fill="#FFFFFF" cx="300.987" cy="427.681" r="0.398" />
          <circle fill="#FFFFFF" cx="264.17" cy="409.391" r="0.398" />
          <circle fill="#FFFFFF" cx="276.973" cy="403.474" r="0.398" />
          <circle fill="#FFFFFF" cx="274.306" cy="397.579" r="0.398" />
          <circle fill="#FFFFFF" cx="258.522" cy="392.5" r="0.398" />
          <circle fill="#FFFFFF" cx="277.372" cy="392.124" r="0.398" />
          <circle fill="#FFFFFF" cx="286.925" cy="387.712" r="0.398" />
          <circle fill="#FFFFFF" cx="187.698" cy="400.515" r="0.398" />
          <circle fill="#FFFFFF" cx="223.556" cy="320.04" r="0.398" />
          <circle fill="#FFFFFF" cx="182.189" cy="330.476" r="0.398" />
          <circle fill="#FFFFFF" cx="111.988" cy="332.036" r="0.398" />
          <circle fill="#FFFFFF" cx="134.313" cy="327.195" r="0.398" />
          <circle fill="#FFFFFF" cx="129.633" cy="332.435" r="0.398" />
          <circle fill="#FFFFFF" cx="139.961" cy="338.437" r="0.398" />
          <circle fill="#FFFFFF" cx="149.213" cy="336.447" r="0.398" />
          <circle fill="#FFFFFF" cx="153.947" cy="325.742" r="0.878" />
          <circle fill="#FFFFFF" cx="52.869" cy="317.458" r="0.398" />
          <circle fill="#FFFFFF" cx="29.039" cy="294.596" r="0.398" />
          <circle fill="#FFFFFF" cx="39.206" cy="309.626" r="0.398" />
          <circle fill="#FFFFFF" cx="60.831" cy="326.549" r="0.398" />
          <circle fill="#FFFFFF" cx="55.559" cy="357.588" r="0.398" />
          <circle fill="#FFFFFF" cx="51.502" cy="368.132" r="0.398" />
          <circle fill="#FFFFFF" cx="76.216" cy="354.091" r="0.398" />
          <circle fill="#FFFFFF" cx="41.465" cy="372.865" r="0.398" />
          <circle fill="#FFFFFF" cx="39.029" cy="378.998" r="0.398" />
          <circle fill="#FFFFFF" cx="28.339" cy="381.827" r="0.398" />
          <circle fill="#FFFFFF" cx="17.312" cy="381.03" r="0.398" />
          <circle fill="#FFFFFF" cx="13.438" cy="376.147" r="0.398" />
          <circle fill="#FFFFFF" cx="55.16" cy="388.896" r="0.398" />
          <circle fill="#FFFFFF" cx="65.479" cy="383.678" r="0.398" />
          <circle fill="#FFFFFF" cx="68.631" cy="386.583" r="0.878" />
          <circle fill="#FFFFFF" cx="64.112" cy="390.51" r="0.398" />
          <circle fill="#FFFFFF" cx="99.185" cy="407.885" r="0.398" />
          <circle fill="#FFFFFF" cx="112.526" cy="417.675" r="0.398" />
          <circle fill="#FFFFFF" cx="116.722" cy="420.15" r="0.398" />
          <circle fill="#FFFFFF" cx="113.817" cy="420.096" r="0.398" />
          <circle fill="#FFFFFF" cx="115.431" cy="421.28" r="0.398" />
          <circle fill="#FFFFFF" cx="115.431" cy="419.827" r="0.398" />
          <circle fill="#FFFFFF" cx="72.99" cy="427.412" r="0.398" />
          <circle fill="#FFFFFF" cx="54.375" cy="418.074" r="0.398" />
          <circle fill="#FFFFFF" cx="52.815" cy="435.589" r="0.398" />
          <circle fill="#FFFFFF" cx="93.268" cy="454.847" r="0.398" />
          <circle fill="#FFFFFF" cx="87.405" cy="464.96" r="0.398" />
          <circle fill="#FFFFFF" cx="87.243" cy="468.51" r="0.398" />
          <circle fill="#FFFFFF" cx="55.828" cy="473.567" r="0.398" />
          <circle fill="#FFFFFF" cx="107.276" cy="491.05" r="0.398" />
          <circle fill="#FFFFFF" cx="122.586" cy="500.141" r="0.398" />
          <circle fill="#FFFFFF" cx="108.976" cy="518.323" r="0.398" />
          <circle fill="#FFFFFF" cx="106.932" cy="528.759" r="0.398" />
          <circle fill="#FFFFFF" cx="134.366" cy="516.925" r="0.398" />
          <circle fill="#FFFFFF" cx="150.02" cy="526.285" r="0.398" />
          <circle fill="#FFFFFF" cx="124.038" cy="456.245" r="0.398" />
          <circle fill="#FFFFFF" cx="136.841" cy="461.786" r="0.398" />
          <circle fill="#FFFFFF" cx="157.067" cy="453.448" r="0.398" />
          <circle fill="#FFFFFF" cx="162.608" cy="449.844" r="0.398" />
          <circle fill="#FFFFFF" cx="193.324" cy="454.245" r="0.398" />
          <circle fill="#FFFFFF" cx="206.45" cy="446.401" r="0.398" />
          <circle fill="#FFFFFF" cx="229.151" cy="448.392" r="0.398" />
          <circle fill="#FFFFFF" cx="232.809" cy="446.939" r="0.398" />
          <circle fill="#FFFFFF" cx="203.2" cy="477.408" r="0.398" />
          <circle fill="#FFFFFF" cx="189.992" cy="492.449" r="0.398" />
          <circle fill="#FFFFFF" cx="186.654" cy="507.156" r="0.398" />
          <circle fill="#FFFFFF" cx="202.254" cy="529.614" r="0.398" />
          <circle fill="#FFFFFF" cx="179.177" cy="541.912" r="0.398" />
          <circle fill="#FFFFFF" cx="178.208" cy="554.688" r="0.398" />
          <circle fill="#FFFFFF" cx="160.757" cy="554.688" r="0.398" />
          <circle fill="#FFFFFF" cx="150.989" cy="550.836" r="0.398" />
          <circle fill="#FFFFFF" cx="187.622" cy="507.694" r="0.398" />
          <circle fill="#FFFFFF" cx="173.389" cy="433.276" r="0.398" />
          <circle fill="#FFFFFF" cx="181.974" cy="425.745" r="0.398" />
          <circle fill="#FFFFFF" cx="142.328" cy="429.402" r="0.398" />
          <circle fill="#FFFFFF" cx="162.608" cy="456.407" r="0.398" />
          <circle fill="#FFFFFF" cx="69.814" cy="392.177" r="0.398" />
          <circle fill="#FFFFFF" cx="77.076" cy="394.544" r="0.398" />
          <circle fill="#FFFFFF" cx="75.624" cy="396.427" r="0.398" />
          <circle fill="#FFFFFF" cx="77.076" cy="398.041" r="0.398" />
          <circle fill="#FFFFFF" cx="79.712" cy="390.295" r="0.398" />
          <circle fill="#FFFFFF" cx="90.686" cy="389.057" r="0.398" />
          <circle fill="#FFFFFF" cx="92.085" cy="387.39" r="0.398" />
          <circle fill="#FFFFFF" cx="88.911" cy="399.009" r="0.398" />
          <circle fill="#FFFFFF" cx="86.705" cy="372.65" r="0.398" />
          <circle fill="#FFFFFF" cx="72.592" cy="403.743" r="0.398" />
          <circle fill="#FFFFFF" cx="69.76" cy="397.256" r="0.398" />
          <circle fill="#FFFFFF" cx="69.76" cy="394.921" r="0.398" />
          <circle fill="#FFFFFF" cx="69.029" cy="394.146" r="0.398" />
          <circle fill="#FFFFFF" cx="66.748" cy="398.041" r="0.398" />
          <circle fill="#FFFFFF" cx="48.781" cy="394.383" r="0.398" />
          <circle fill="#FFFFFF" cx="72.558" cy="329.669" r="0.398" />
          <circle fill="#FFFFFF" cx="29.039" cy="324.559" r="0.398" />
          <circle fill="#FFFFFF" cx="30.007" cy="327.571" r="0.398" />
          <circle fill="#FFFFFF" cx="66.318" cy="267.591" r="0.398" />
          <circle fill="#FFFFFF" cx="47.651" cy="269.151" r="0.398" />
          <circle fill="#FFFFFF" cx="34.095" cy="249.323" r="0.398" />
          <circle fill="#FFFFFF" cx="48.404" cy="236.036" r="0.398" />
          <circle fill="#FFFFFF" cx="11.847" cy="236.176" r="0.398" />
          <circle fill="#FFFFFF" cx="45.607" cy="247.957" r="0.398" />
          <circle fill="#FFFFFF" cx="23.52" cy="257.478" r="0.398" />
          <circle fill="#FFFFFF" cx="13.654" cy="267.591" r="0.398" />
          <circle fill="#FFFFFF" cx="44.24" cy="284.267" r="0.398" />
          <circle fill="#FFFFFF" cx="114.248" cy="207.795" r="0.398" />
          <circle fill="#FFFFFF" cx="196.767" cy="270.927" r="0.398" />
          <circle fill="#FFFFFF" cx="153.087" cy="259.63" r="0.398" />
          <circle fill="#FFFFFF" cx="171.452" cy="251.238" r="0.398" />
          <circle fill="#FFFFFF" cx="172.022" cy="246.343" r="0.398" />
          <circle fill="#FFFFFF" cx="117.776" cy="222.835" r="0.398" />
          <circle fill="#FFFFFF" cx="199.026" cy="276.629" r="0.398" />
          <circle fill="#FFFFFF" cx="282.944" cy="273.509" r="0.398" />
          <circle fill="#FFFFFF" cx="257.608" cy="285.774" r="0.398" />
          <circle fill="#FFFFFF" cx="195.508" cy="288.302" r="0.398" />
          <circle fill="#FFFFFF" cx="230.496" cy="291.96" r="0.398" />
          <circle fill="#FFFFFF" cx="220.221" cy="205.513" r="0.398" />
          <circle fill="#FFFFFF" cx="211.991" cy="202.017" r="0.398" />
          <circle fill="#FFFFFF" cx="212.744" cy="134.129" r="0.398" />
          <circle fill="#FFFFFF" cx="192.517" cy="151.558" r="0.398" />
          <circle fill="#FFFFFF" cx="370.09" cy="13.901" r="0.398" />
          <circle fill="#FFFFFF" cx="321.999" cy="54.353" r="0.398" />
          <circle fill="#FFFFFF" cx="334.479" cy="72.321" r="0.398" />
          <circle fill="#FFFFFF" cx="373.533" cy="63.821" r="0.398" />
          <circle fill="#FFFFFF" cx="416.137" cy="30.2" r="0.398" />
          <circle fill="#FFFFFF" cx="450.673" cy="40.26" r="0.398" />
          <circle fill="#FFFFFF" cx="433.351" cy="98.626" r="0.878" />
          <circle fill="#FFFFFF" cx="418.181" cy="112.311" r="0.398" />
          <circle fill="#FFFFFF" cx="466.381" cy="100.777" r="0.398" />
          <circle fill="#FFFFFF" cx="462.723" cy="121.595" r="0.398" />
          <circle fill="#FFFFFF" cx="456.59" cy="143.328" r="0.398" />
          <circle fill="#FFFFFF" cx="516.086" cy="138.67" r="0.398" />
          <circle fill="#FFFFFF" cx="532.87" cy="143.597" r="0.398" />
          <circle fill="#FFFFFF" cx="543.79" cy="142.93" r="0.398" />
          <circle fill="#FFFFFF" cx="572.569" cy="173.022" r="0.398" />
          <circle fill="#FFFFFF" cx="574.291" cy="184.588" r="0.398" />
          <circle fill="#FFFFFF" cx="545.995" cy="198.359" r="0.398" />
          <circle fill="#FFFFFF" cx="517.646" cy="197.96" r="0.398" />
          <circle fill="#FFFFFF" cx="558.314" cy="220.576" r="0.398" />
          <circle fill="#FFFFFF" cx="553.472" cy="235.477" r="0.398" />
          <circle fill="#FFFFFF" cx="550.245" cy="246.935" r="0.398" />
          <circle fill="#FFFFFF" cx="586.502" cy="255.004" r="0.398" />
          <circle fill="#FFFFFF" cx="593.71" cy="281.524" r="0.398" />
          <circle fill="#FFFFFF" cx="598.39" cy="239.941" r="0.398" />
          <circle fill="#FFFFFF" cx="535.021" cy="194.217" r="0.398" />
          <circle fill="#FFFFFF" cx="510.061" cy="158.175" r="0.398" />
          <circle fill="#FFFFFF" cx="515.44" cy="168.181" r="0.398" />
          <circle fill="#FFFFFF" cx="503.584" cy="165.652" r="0.398" />
          <circle fill="#FFFFFF" cx="495.429" cy="162.21" r="0.398" />
          <circle fill="#FFFFFF" cx="497.258" cy="173.022" r="0.398" />
          <circle fill="#FFFFFF" cx="477.623" cy="175.873" r="0.398" />
          <circle fill="#FFFFFF" cx="481.981" cy="180.768" r="0.398" />
          <circle fill="#FFFFFF" cx="497.688" cy="185.61" r="0.398" />
          <circle fill="#FFFFFF" cx="461.969" cy="170.332" r="0.398" />
          <circle fill="#FFFFFF" cx="458.634" cy="178.908" r="0.398" />
          <circle fill="#FFFFFF" cx="444.271" cy="171.301" r="0.398" />
          <circle fill="#FFFFFF" cx="419.128" cy="179.854" r="0.398" />
          <circle fill="#FFFFFF" cx="438.408" cy="185.61" r="0.398" />
          <circle fill="#FFFFFF" cx="436.364" cy="212.453" r="0.398" />
          <circle fill="#FFFFFF" cx="455.353" cy="199.327" r="0.398" />
          <circle fill="#FFFFFF" cx="460.033" cy="212.851" r="0.398" />
          <circle fill="#FFFFFF" cx="481.582" cy="214.443" r="0.398" />
          <circle fill="#FFFFFF" cx="476.494" cy="196.691" r="0.398" />
          <circle fill="#FFFFFF" cx="483.433" cy="188.622" r="0.398" />
          <circle fill="#FFFFFF" cx="453.363" cy="149.913" r="0.398" />
          <circle fill="#FFFFFF" cx="440.237" cy="135.044" r="0.398" />
          <circle fill="#FFFFFF" cx="423.937" cy="138.67" r="0.398" />
          <circle fill="#FFFFFF" cx="427.111" cy="138.271" r="0.398" />
          <circle fill="#FFFFFF" cx="404.249" cy="145.426" r="0.398" />
          <circle fill="#FFFFFF" cx="383.216" cy="174.421" r="0.398" />
          <circle fill="#FFFFFF" cx="373.102" cy="189.268" r="0.398" />
          <circle fill="#FFFFFF" cx="371.004" cy="189.322" r="0.398" />
          <circle fill="#FFFFFF" cx="376.222" cy="193.302" r="0.398" />
          <circle fill="#FFFFFF" cx="402.797" cy="197.713" r="0.398" />
          <circle fill="#FFFFFF" cx="381.419" cy="232.496" r="0.398" />
          <circle fill="#FFFFFF" cx="344.699" cy="222.674" r="0.398" />
          <circle fill="#FFFFFF" cx="339.966" cy="221.705" r="0.398" />
          <circle fill="#FFFFFF" cx="309.949" cy="229.452" r="0.398" />
          <circle fill="#FFFFFF" cx="329.099" cy="258.608" r="0.398" />
          <circle fill="#FFFFFF" cx="334.263" cy="274.132" r="0.398" />
          <circle fill="#FFFFFF" cx="325.71" cy="288.732" r="0.398" />
          <circle fill="#FFFFFF" cx="311.687" cy="295.779" r="0.398" />
          <circle fill="#FFFFFF" cx="360.138" cy="292.39" r="0.398" />
          <circle fill="#FFFFFF" cx="358.255" cy="302.557" r="0.398" />
          <circle fill="#FFFFFF" cx="362.882" cy="302.956" r="0.398" />
          <circle fill="#FFFFFF" cx="361.483" cy="312.025" r="0.398" />
          <circle fill="#FFFFFF" cx="353.253" cy="315.306" r="0.398" />
          <circle fill="#FFFFFF" cx="335.232" cy="319.233" r="0.398" />
          <circle fill="#FFFFFF" cx="359.654" cy="327.571" r="0.398" />
          <circle fill="#FFFFFF" cx="363.28" cy="324.075" r="0.398" />
          <circle fill="#FFFFFF" cx="377.482" cy="325.419" r="0.398" />
          <circle fill="#FFFFFF" cx="364.334" cy="335.21" r="0.398" />
          <circle fill="#FFFFFF" cx="357.825" cy="238.812" r="0.398" />
          <circle fill="#FFFFFF" cx="392.414" cy="244.837" r="0.398" />
          <circle fill="#FFFFFF" cx="406.347" cy="261.19" r="0.398" />
          <circle fill="#FFFFFF" cx="380.849" cy="274.047" r="0.398" />
          <circle fill="#FFFFFF" cx="371.435" cy="289.969" r="0.398" />
          <circle fill="#FFFFFF" cx="429.478" cy="224.556" r="0.398" />
          <circle fill="#FFFFFF" cx="434.75" cy="226.224" r="0.398" />
          <circle fill="#FFFFFF" cx="433.674" cy="234.616" r="0.398" />
          <circle fill="#FFFFFF" cx="449.543" cy="238.22" r="0.398" />
          <circle fill="#FFFFFF" cx="441.635" cy="251.991" r="0.398" />
          <circle fill="#FFFFFF" cx="462.83" cy="240.663" r="0.398" />
          <circle fill="#FFFFFF" cx="475.31" cy="264.471" r="0.398" />
          <circle fill="#FFFFFF" cx="476.494" cy="268.99" r="0.398" />
          <circle fill="#FFFFFF" cx="488.974" cy="261.513" r="0.398" />
          <circle fill="#FFFFFF" cx="492.847" cy="245.697" r="0.398" />
          <circle fill="#FFFFFF" cx="447.983" cy="283.891" r="0.398" />
          <circle fill="#FFFFFF" cx="478.269" cy="294.273" r="0.398" />
          <circle fill="#FFFFFF" cx="481.55" cy="312.832" r="0.398" />
          <circle fill="#FFFFFF" cx="470.953" cy="324.989" r="0.878" />
          <circle fill="#FFFFFF" cx="467.08" cy="330.046" r="0.398" />
          <circle fill="#FFFFFF" cx="473.804" cy="331.444" r="0.398" />
          <circle fill="#FFFFFF" cx="451.103" cy="334.278" r="0.398" />
          <circle fill="#FFFFFF" cx="474.396" cy="339.298" r="0.398" />
          <circle fill="#FFFFFF" cx="465.681" cy="354.468" r="0.398" />
          <circle fill="#FFFFFF" cx="486.93" cy="338.545" r="0.398" />
          <circle fill="#FFFFFF" cx="462.992" cy="351.402" r="0.398" />
          <circle fill="#FFFFFF" cx="472.89" cy="370.229" r="0.398" />
          <circle fill="#FFFFFF" cx="483.541" cy="381.957" r="0.398" />
          <circle fill="#FFFFFF" cx="470.953" cy="386.529" r="0.398" />
          <circle fill="#FFFFFF" cx="462.238" cy="387.174" r="0.398" />
          <circle fill="#FFFFFF" cx="452.394" cy="381.365" r="0.398" />
          <circle fill="#FFFFFF" cx="451.996" cy="393.253" r="0.398" />
          <circle fill="#FFFFFF" cx="444.863" cy="396.481" r="0.398" />
          <circle fill="#FFFFFF" cx="517.377" cy="392.231" r="0.398" />
          <circle fill="#FFFFFF" cx="411.888" cy="294.004" r="0.398" />
          <circle fill="#FFFFFF" cx="404.948" cy="308.098" r="0.398" />
          <circle fill="#FFFFFF" cx="400.914" cy="320.385" r="0.398" />
          <circle fill="#FFFFFF" cx="416.944" cy="338.115" r="0.398" />
          <circle fill="#FFFFFF" cx="419.526" cy="346.507" r="0.398" />
          <circle fill="#FFFFFF" cx="422.7" cy="352.047" r="0.398" />
          <circle fill="#FFFFFF" cx="410.973" cy="358.449" r="0.398" />
          <circle fill="#FFFFFF" cx="394.405" cy="359.256" r="0.398" />
          <circle fill="#FFFFFF" cx="372.672" cy="352.585" r="0.398" />
          <circle fill="#FFFFFF" cx="382.409" cy="369.476" r="0.398" />
          <circle fill="#FFFFFF" cx="371.489" cy="360.063" r="0.398" />
          <circle fill="#FFFFFF" cx="359.224" cy="384.485" r="0.398" />
          <circle fill="#FFFFFF" cx="352.231" cy="413.587" r="0.398" />
          <circle fill="#FFFFFF" cx="345.883" cy="418.644" r="0.398" />
          <circle fill="#FFFFFF" cx="350.832" cy="421.441" r="0.398" />
          <circle fill="#FFFFFF" cx="355.512" cy="422.302" r="0.398" />
          <circle fill="#FFFFFF" cx="354.866" cy="428.972" r="0.398" />
          <circle fill="#FFFFFF" cx="361.537" cy="430.478" r="0.398" />
          <circle fill="#FFFFFF" cx="332.703" cy="427.95" r="0.398" />
          <circle fill="#FFFFFF" cx="314.898" cy="443.604" r="0.398" />
          <circle fill="#FFFFFF" cx="312.961" cy="450.92" r="0.398" />
          <circle fill="#FFFFFF" cx="315.167" cy="454.416" r="0.398" />
          <circle fill="#FFFFFF" cx="340.902" cy="453.125" r="0.398" />
          <circle fill="#FFFFFF" cx="352.629" cy="442.367" r="0.398" />
          <circle fill="#FFFFFF" cx="360.407" cy="443.604" r="0.398" />
          <circle fill="#FFFFFF" cx="359.6" cy="458.043" r="0.398" />
          <circle fill="#FFFFFF" cx="377.621" cy="455.815" r="0.398" />
          <circle fill="#FFFFFF" cx="387.089" cy="485.8" r="0.398" />
          <circle fill="#FFFFFF" cx="369.197" cy="490.297" r="0.398" />
        </svg>
      </div>
    </div>
  </>
);

export default Stars;
